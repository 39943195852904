import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotificationUsers } from './notification.reducer';

const NotificationApiActions = createActionGroup({
  source: 'Users[GRAPH API] notification',
  events: {
    initNotification: emptyProps(),
    loadSuccessNotification: props<{ notificationUsers: NotificationUsers }>(),
    loadErrorNotification: props<{ errorMessage: string }>(),
  },
});

export const { initNotification, loadSuccessNotification, loadErrorNotification } = NotificationApiActions;
