import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateNotificationContratos } from './notification.reducer';
import { AppState } from 'src/app/app.state';

const selectNotification = (state: AppState) => state.notificationContratosState;

export const selectNotificationUsers = createSelector(
  selectNotification,
  (state: StateNotificationContratos) => state.notificationUsers
);

export const selectNotificationLoaded = createSelector(
  selectNotification,
  (state: StateNotificationContratos) => state.loaded
);
