import { NotificationObject, UsersNotificaBase } from '@contratos/models';
import { createReducer, on } from '@ngrx/store';
import { NotificationApiActions } from '.';

export interface NotificationUsers {
  usersNotificaMC: string[];
  usersAutorizaComisionContrato: string[];
  usersNotificaMCSolicitudContrato: NotificationObject;
  usersNotificaSolicitudPago: NotificationObject;
  usersNotificaSupervisaContrato: UsersNotificaBase;
  usersNotificaControlInternoContrato: UsersNotificaBase;
}

export interface StateNotificationContratos {
  notificationUsers: NotificationUsers;
  loaded: boolean;
}

const initialState: StateNotificationContratos = {
  notificationUsers: {
    usersNotificaMC: [],
    usersAutorizaComisionContrato: [],
    usersNotificaMCSolicitudContrato: null,
    usersNotificaSolicitudPago: null,
    usersNotificaSupervisaContrato: null,
    usersNotificaControlInternoContrato: null,
  },
  loaded: false,
};

export const notificationReducer = createReducer<StateNotificationContratos>(
  initialState,
  on(
    NotificationApiActions.loadSuccessNotification,
    (state, action): StateNotificationContratos => ({
      ...state,
      notificationUsers: action.notificationUsers,
      loaded: true,
    })
  )
);
