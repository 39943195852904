import { inject, Injectable } from '@angular/core';
import { NotificationObject, UsersNotificaBase } from '@contratos/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, concatMap, filter, map, switchMap } from 'rxjs';
import { NotificationApiActions } from '.';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { sl } from 'date-fns/locale';
import { AlertService, GraphService } from '@core/services';

@Injectable()
export class NotificationEffects {
  private readonly _graphService = inject(GraphService);
  private readonly actions$ = inject(Actions);
  private readonly _alertService = inject(AlertService);

  private readonly store = inject(Store);

  /*PREFETCH_NOTIFICATIONS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      // Listens to every navigation event => filter only route that you need
      filter(({ payload }) => payload.routerState.url === 'myRoute'),
      // Dispatch action to preload data
      map(() => NotificationApiActions.initNotification())
    );
  });*/

  GET_USERS_NOTIFICA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationApiActions.initNotification),
      concatMap(() =>
        combineLatest([
          this._graphService.getMembersGroups('82c98979-7735-4afb-9cb0-9f7f9f92f2ab'),
          this._graphService.getMembersGroups('a3d1f3a4-29d5-4da6-96fb-5d6a6a6b2e34'),
          this._graphService.getMembersGroups('ba99fe70-c730-491d-b47c-0784cfca7eb6'),
          this._graphService.getMembersGroups('0f0dd293-8a4a-43b8-b42d-52bdd36aa631'),
          this._graphService.getMembersGroups('1b162dd1-8955-4333-930a-d2d77f060cda'),
          this._graphService.getMembersGroups('b3c223d5-41e5-4890-aa10-5a2eae14b78b'),
          this._graphService.getMembersGroups('643a9514-3cc2-40d5-8415-850031c81ea5'),
          this._graphService.getMembersGroups('4922fe74-ddad-44d3-9c81-739531bc2d12'), //Grupo de contabilidad
        ]).pipe(
          map(
            ([
              _usersNotificaMC,
              _usersNotificaAutComision,
              _notificaMCSolContrato,
              _notificationSolicitudContrato,
              _notificationSupervisorContrato,
              _notificationControlInternoContrato,
              _notificaPagoTransferencia,
              _notificaContabilidad,
            ]) => {
              console.log('NotificationApiActions initNotification');

              let usersNotificaMC: string[] = [];
              let usersAutorizaComisionContrato: string[] = [];
              let usersNotificaMCSolicitudContrato: NotificationObject = {};
              let usersNotificaSolicitudPago: NotificationObject = {};
              let usersNotificaSupervisaContrato: UsersNotificaBase = { email: [], number: [] };
              let usersNotificaControlInternoContrato: UsersNotificaBase = { email: [], number: [] };

              if (Array.isArray(_usersNotificaMC.value) && _usersNotificaMC.value.length > 0)
                usersNotificaMC = _usersNotificaMC.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_usersNotificaAutComision.value) && _usersNotificaAutComision.value.length > 0)
                usersAutorizaComisionContrato = _usersNotificaAutComision.value
                  .filter((user) => user.mobilePhone)
                  .map((user) => user.mobilePhone);

              if (Array.isArray(_notificaMCSolContrato.value) && _notificaMCSolContrato.value.length > 0) {
                usersNotificaMCSolicitudContrato = {
                  ...usersNotificaMCSolicitudContrato,
                  mesa_controlEmail: _notificaMCSolContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificaMCSolContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationSolicitudContrato.value) &&
                _notificationSolicitudContrato.value.length > 0 &&
                Array.isArray(_notificaPagoTransferencia.value) &&
                _notificaPagoTransferencia.value.length > 0 &&
                Array.isArray(_notificaContabilidad.value) &&
                _notificaContabilidad.value.length > 0
              ) {
                usersNotificaSolicitudPago = {
                  ...usersNotificaSolicitudPago,
                  mesa_controlEmail: _notificationSolicitudContrato.value.map((user) => user.userPrincipalName),
                  mesa_controlNumber: _notificationSolicitudContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),

                  usuarioPagosEmail: _notificaPagoTransferencia.value.map((user) => user.userPrincipalName),
                  usuarioPagosNumber: _notificaPagoTransferencia.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),

                  usuarioContabilidadEmail: _notificaContabilidad.value.map((user) => user.userPrincipalName),
                  usuarioContabilidadNumber: _notificaContabilidad.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationSupervisorContrato.value) &&
                _notificationSupervisorContrato.value.length > 0
              ) {
                usersNotificaSupervisaContrato = {
                  email: _notificationSupervisorContrato.value.map((user) => user.userPrincipalName),
                  number: _notificationSupervisorContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              if (
                Array.isArray(_notificationControlInternoContrato.value) &&
                _notificationControlInternoContrato.value.length > 0
              ) {
                usersNotificaControlInternoContrato = {
                  email: _notificationControlInternoContrato.value.map((user) => user.userPrincipalName),
                  number: _notificationControlInternoContrato.value
                    .filter((user) => user.mobilePhone)
                    .map((user) => user.mobilePhone),
                };
              }

              return NotificationApiActions.loadSuccessNotification({
                notificationUsers: {
                  usersNotificaMC,
                  usersAutorizaComisionContrato,
                  usersNotificaMCSolicitudContrato,
                  usersNotificaSolicitudPago,
                  usersNotificaSupervisaContrato,
                  usersNotificaControlInternoContrato,
                },
              });
            }
          ),

          catchError(async () =>
            NotificationApiActions.loadErrorNotification({
              errorMessage:
                'Error al cargar la información de notificación obtenida de GRAPH [NotificationApiActionsEffects] STATE EFFECTS, informe al administrador.',
            })
          )
        )
      )
    );
  });

  NOTIFY_ERROR$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationApiActions.loadErrorNotification),
        map((action) => {
          this._alertService.error(action.errorMessage);
        })
      );
    },
    { dispatch: false }
  );
}
